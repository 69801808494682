.heads-up {
  display: flex;
  width: 60%;
  margin: auto;
  justify-content: center;

}
.status-bar {
  display: flex;

  .display-label {
    padding-right: 10px;
    line-height: 24px;
  }

  .stats-display {
    height: 40px;
    background-color: $medium;
    display: flex;
    border-radius: 60px;
    color: $white;
    margin-left: .5em;

    .time-display {
      font: bold 18px $main-sans;
      display: flex;
      padding: 8px;
      padding-left: 0;
      padding-right: 10px;
      border-radius: 50px;
    }
    .wpm-display {
      font: bold 18px $main-sans;
      display: flex;
      padding: 8px;
      padding-left: 1em;
      border-radius: 50px;
    }
    .time-counter {
      background-color: $dark;
      padding: 20px;
      border-radius: 20px;
    }
    .wpm-counter {
      background-color: $dark;
      padding: 20px;
      border-radius: 20px;
      width: 80px;
      text-align: center;
    }
    .counter {
      padding-top: 3px;
      padding-bottom: 3px;
      font-weight: normal;
      line-height: 18px;
    }
  }
}

.start-game-prompt {
  position: absolute;
  text-align: center;
  top: 6em;
  left: 50%;
  transform: translate(-50%, 0);
  font: normal 14px $main-sans;
  color: $white;
  border:1.3px solid $white;
  padding: .5em;
  padding-right: .75em;
  padding-left: .75em;
  z-index: 10;
}

.speed-typer-container {
  margin-top: 6em;

}

.container {
  background-color: $dark;
  width: 40%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  color: $white;
  .word {
    display: flex;
    line-height: 1em;
    border: 1px solid transparent;
    padding: 3px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: .25em;
    margin-bottom:.25em;
    align-items: center;
    .challenge-letter {
      display: block;
      width: 9.61px;
      $white-space: pre-line;
      opacity: .6;
      min-height: 18px;
    }
    .accurate {
      opacity: 1;
    }
    .inaccurate {
      background-color: red;
      opacity: 1;
    }
  }
  .challenge-row {
    width: 100%;
    display: flex;
    padding-top: .25em;
    padding-bottom: .25em;
    justify-content: center;
    border-radius: 999em;
    color: $white;
  }
  .active-challenge-row {
    background-color: $medium;
  }
  .mock-challenge-row {
    min-height: 34px;
  }
}

.old-words-container {
  width: 40%;
  position: absolute;
  z-index: -1;
  left: 30%;
  height: 6em;
  margin: auto;
  margin-top: -4em;
  justify-content: flex-start;
  justify-items: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  overflow: hidden;
  opacity: .4;
}

.old-words-gradient {
  position: relative;
  margin-bottom: -4em;
  bottom: 4em;
  width: 100%;
  height: 6em;
  z-index: 1;
  background-image: linear-gradient(rgba($dark, 1), rgba($dark, 0));
}

.score {
  margin-bottom: 2em;
  margin-top: 2em;
  font: normal 20px 'Source Sans Pro';
  text-align: center;
  color: $white;
}

.odd {
  background-color: $medium;
}

.even {
  background-color: $dark;
}

@keyframes blink {
  from {
    border-color: rgba(255, 255, 255, 1);
  }
  20% {
    border-color: rgba(255, 255, 255, 1);
  }
  50% {
    border-color: rgba(255, 255, 255, 0);
  }
  80% {
    border-color: rgba(255, 255, 255, 1);
  }
  to {
    border-color: rgba(255, 255, 255, 1);
  }
}

.active-letter {
  border-left: 1.5px solid $white;
  position: relative;
  right: 1.5px;
  animation-duration: 1s;
  animation-name: blink;
  animation-iteration-count: infinite;
}

.first-letter {
  width: 11.61px !important;
  border-left: 1.5px solid transparent;
  box-sizing: border-box;
}

.active-letter.first-letter {
  border-left: 1.5px solid $white;
  margin-left: -2px;
  border-right: 0;
  animation-duration: 1s;
  animation-name: blink;
  animation-iteration-count: infinite;
}

.type-capture {
  text-align: center;
  font-size: 12px;
  padding: 1em;
  height: 4em;
  background-color: $dark;
  margin: auto;
  margin-top: 3em;
  width: 30em;
  color: $white;
  cursor: default;
  display: block;
  border: 1px solid $white;
  opacity: 0;
}
