.landing-container {
    display: flex;
    padding-top: 8vh;
    padding-left: 5vw;
    padding-right: 15vw;
    padding-bottom: 12vh;
    height: 100%;
    @media screen and (max-width: $laptop) {
        padding: 5%;
        padding-top: 3em;
        padding-bottom: 3em;
    }

    @media screen and (max-width: $mobile) {
        padding: 5%;
        padding-top: 2em;
        padding-bottom: 3em;
    }

    .landing-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: transparent;
        color: $white;
        margin-right: 50px;
        max-width: 1000px;

        @media screen and (max-width: $laptop) {
            max-width: 700px;
        }

        .landing-title {
            font: bold 72px $main-sans;
            line-height: 1em;
            flex-shrink: 0;

            .blinking-cursor {
                @keyframes blink2 {
                    0% {
                        opacity: 1;
                    }
                    30% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
                animation: blink2 .8s infinite;
            }
            @media screen and (max-width: $laptop) {
                font: bold 48px $main-sans;
                line-height: 48px;
            }
            @media screen and (max-width: $mobile) {
                font: bold 32px $main-sans;
             }
        }
        .landing-text {
            margin-top: 3em;
            color: #eeeeee;
            font: normal 15px $code-mono;
            line-height: 1.25em;
            width: 65%;
            @media screen and (max-width: $tablet) {
                width: 90%;
                margin-top: 1em;
             }
        }
        ul {
            margin-top: 1em;
            margin-bottom: 1em;
            display: inline-block;
            background-color: transparentize($color: $medium, $amount: .6);
            border: 1px solid $medium;
            border-radius: 8px;
            padding: 1em;
            color: #eeeeee;
            font: 600 18px $main-sans;
            backdrop-filter: blur(3px);

            li {
                margin-left: 1em;
                list-style: circle;
            }

            @media screen and (max-width: $tablet) {
                width: 100%;
                flex-direction: column;
                margin-top: 2em;
                font-size: 16px;
            }

            transition: background-color .5s ease-in-out, backdrop-filter .5s ease-in-out, transform .5s ease-in-out;
        }

        ul:hover {
            background-color: transparentize($color: $medium, $amount: .4);
            backdrop-filter: blur(6px);
            transform: scale(1.025);
        }

        @media screen and (max-width: $mobile) {
           width: 90%;
           margin-left: 5%;
           margin-right: 5%;
        }
    }
    
    @keyframes scrollFloat {
        from {
            margin-bottom: -.5em;
          }
        
        50% {
            margin-bottom: .5em;
        }
    
        to {
            margin-bottom: -.5em;
        }
    }

    .landing-right {
        min-height: 70%;
        max-width: 30%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .landing-illustration-1 > img {
            height: 400px;
            
            @media screen and (max-width: $laptop) {
            }
            
            @media screen and (max-width: $tablet) {
                height: calc(100px + 15vw);

            }

            @media screen and (max-width: $mobile) {
                width: 80%;
            }
        }

        .landing-illustration-1 {
            display: flex;
            justify-content: center;
            transform: rotate(-20deg);
            margin-top: -33%;
        }
        @media screen and (max-width: $mobile) {
            display: none;
        }
        .scroll-prompt {
            font: 600 20px $main-sans;
            line-height: 48px;
            color: $white;
            bottom: -100px;
            animation: scrollFloat 2s infinite;
            display: none;
        }
    }
    .landing-buttons {
        display: flex;
        margin-top: 8em;
        @media screen and (max-width: $mobile) {
            width: 100%;
            flex-direction: column;
        }

        .landing-button {
            flex-grow: 4;
            text-align: center;
            background-color: $dark;
            font: 600 18px $main-sans;
            display: inline-block;
            color: $white;
            border-radius: 999em;
            padding: .5em;
            padding-left: 3em;
            padding-right: 3em;
            height: 100%;
            border: 2px solid transparent;
            @media screen and (max-width: $mobile) {
                width: 100%;
                margin: auto;
            }
    
        }

        .register-button {
            background-color: $white;
            color: $dark;
        }

        .login-button {
            margin-left: 10px;
            background-color: transparent;
            border: 2px solid $white;

            @media screen and (max-width: $mobile) {
                width: 100%;
                margin: 0;
                margin-top: 1em;
                height: 45px;
            }
        }
    }

}