.active-user-row {
    .active-user-row-button {
        background-color: $light;
        padding: 8px;
        border-radius: 4px;
        font-family: $main-sans;
        color: $white;
        text-transform: capitalize;
    }
    .active-user-row-name {
        font-weight: 600;
        font-family: $main-sans;
        margin-left: 4px;
    }
    background-color: $white;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}
