//COLORS 
$dark: #112d32;
$medium: #254E58;
$light-border: #2D5F6C;
$light: #40798C;

$light-green: #7B9597;
$dark-green: #64787A;
$darker-green: #424F4B;
$highlight-green: #B5C5C7;

$white: #F0F7F4;

//Fonts 
$main-sans: 'DM Sans';
$logo-display: 'Fugaz One';
$code-mono: 'JetBrains Mono';

//Screen Size Maximums
$mobile: 780px;
$tablet: 1150px;
$laptop: 1525px;
