.post-game-container {
  width: 50%;
  margin: auto;
  max-width: 550px;
}

.statistics-display {
  margin: auto;
  margin-top: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.statistics-display-title {
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  .game-title {
    font: normal 24px $main-sans;
    color: $white;
    display: inline-block;
    line-height: 24px;
    text-transform: capitalize;
  }
  .date-display {
    font: normal 16px $main-sans;
    line-height: 24px;
    color: $white;
  }
  .divider {
    width: 2px;
    height: 1.5em;
    background-color: $white;
    margin-left: 1em;
    margin-right: 1em;
  }
}

.statistics-container {
  padding: 2em;
  padding-top: 1em;
  padding-bottom: .5em;
  margin-top: 1em;
  background-color: #235363;
  border: 2px solid $white;
  border-radius: 10px;
  display: flex;

  .statistics-section {
    width: calc(50% + 3em);
  }

  .statistics-tips {
    width: 50%;
    .statistics-tip {
      font: normal 14px $main-sans;
      padding-top: .25em;
      line-height: 1em;
      height: 36px;
      margin-bottom: .5em;
      margin-left: .75em;
      color: $white;
      opacity: .5;
    }
  }

  .statistic-row {
    display: flex;
    margin-bottom: .5em;
    justify-content: flex-end;
    .statistic-title {
      height: 1.75em;
      background-color: rgba(0,0,0,.2);
      color: $white;
      border-radius: 100px;
      font: bold 16px $main-sans;
      padding: .25em;
      padding-left: .75em;
      padding-right: .75em;
      margin-top: .25em;
      margin-right: .5em;
    }
    .statistic-display {
      font: normal 16px $main-sans;
      background-color: $white;
      border-radius: 50px;
      width: 2.25em;
      height: 2.25em;
      box-sizing: content-box;
      line-height: 2.25em;
      text-align: center;
    }
    .mistake-display {
      background-color: #B84545;
      color: $white;
    }
  }
  
}