.registration-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  margin-left: 20%;
  margin-right: 2em;
}

.registration-title {
    font: bold 48px $main-sans;
    color: $white;
    text-align: center;
    margin-top: 1em;
    margin-bottom: .5em;
}

.registration-form {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  min-width: 40vw;
  margin-right: 10vw;
  form {
    padding-top: 1em;
    width: 100%;
    padding-bottom: 1.5em;

    .registration-input-half:first-child {
      margin-right: .25em;
      width: 50%;
    }

    .registration-input-half:nth-child(2) {
      margin-left: .25em;
      width: 50%;
    }

    .tos-container {
      width: 100%;
      margin-top: 1em;
      font: 600 16px $main-sans;
      display: flex;
      justify-content: center;
      color: $white;
      
      input {
        margin-top: 5px;
        margin-right: .5em;
      }
    }
  }

  .registration-divider {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
    color: $white;
    font: 600 16px $main-sans;
  }

  .skip-registration {
    border-radius: 3px;
    width: 100%;
    font: bold 18px $main-sans;
    text-align: center;
    padding-top: .75em;
    a {
      text-decoration: none;
    }

    .skip-registration-button {
      text-align: center;
      margin: 1em;
      margin-top: .5em;
      height: 2.5em;
      font: 600 16px $main-sans;
      color: $white;
      border-radius: 3px;
      background-color: $light;
      line-height: 40px;
    }
  }

  .login-link {
    margin-top: 1em;
    text-align: center;
    font: bold 16px $main-sans;
    color: $white;
    
    a {
      color: $light;
    }
  }
}