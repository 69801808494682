.rounded-title {
  font: bold 18px $main-sans;
  padding-left: 1em;
  padding-right: 1em;
  background-color: $medium;
  display: inline-block;
  border-radius: 60px;
  color: $white;
  line-height: 40px;
  height:40px;
  overflow: hidden;;
}

.title-container {
  text-align: center;
}