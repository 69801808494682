.header-container {
  height: 60px;
  width: 100%;
  background-color: $dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  .promo {
    opacity: .3;
    font: normal 16px $main-sans;
    line-height: 60px;
    padding-right: 1em;
    color: $white;
    flex-shrink: 0;
    
    a {
      text-decoration: underline;
    }
  }

  .promo-dark {
    font: normal 16px $main-sans;
    line-height: 60px;
    padding-right: 1em;
    color: $white;
    flex-shrink: 0;
    opacity: .3;
    a {
      text-decoration: underline;
    }
  }

  .logo {
    font: normal 1.5em $logo-display;
    line-height: 60px;
    padding-left: 9px;
    transition: ease .25s;

    a {
      text-decoration: none;
    }
  }
  .logo:hover {
    cursor: pointer;
    opacity: 1;
  }

  .speedtyper-word {
    align-items: center;
    display: flex;
    gap: 10px;
    color: $white;
    opacity: .3;
    font: bold 1.5em $main-sans;
    line-height: 60px;
    padding-left: 9px;
    transition: ease .25s;
    a {
      text-decoration: none;
    }
  }
  
  .game-title-container {
    margin-top: 1em;
    color: $white;
    width:  33.333%;

    .game-title {
      font: normal 1em $main-sans;
      margin: auto;
      text-align: center;
      width: 120px;
      background-color: $medium;
      padding-top: .5em;
      padding-bottom: .5em;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  
  .spacer {
    width: 33.333%;
  }
}

//Landing customizations
.landing-header {
  background-color: $medium;

  .logo {
    opacity: 1;
  }
}