.login-form {
  width: 35vw;
  border-radius: 3px;
  margin-top: 15vh;

  @media (max-width: $tablet) {
    width: 55vw;
  }

  @media (max-width: $mobile) {
    width: 80%;
  }

  .login-title {
    font: bold 24px $main-sans;
    color: $white;
    text-align: center;
    margin-top: 1em;
    margin-bottom: .5em;
  }

  .register-link {
    margin-bottom: 1em;
    margin-top: 1em;
    text-align: center;
    font: bold 16px $main-sans;
    color: $white;
    a {
      color: $light;
    }
  }
}