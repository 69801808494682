//VARIABLES 
@import 'abstracts/variables';

//COMPONENTS

//atoms 
@import 'components/atoms/roundedtitle';
@import 'components/atoms/tag';

//molecules
@import 'components/molecules/form';
@import 'components/molecules/registergraphic';
@import 'components/molecules/statisticsdisplay';
@import 'components/molecules/matchhistory';
@import 'components/molecules/leaderboard';
@import 'components/molecules/landingsectionone';
@import 'components/molecules/landingsectiontwo';
@import 'components/molecules/navigatemenubar';
@import 'components/molecules/activeuserrow';
@import 'components/molecules/keyboard';

//organisms
@import 'components/organisms/speedtyper';
@import 'components/organisms/registerform';
@import 'components/organisms/loginform';
@import 'components/organisms/welcome';

//layout components
@import 'layout/header';
@import 'layout/background-container';

//pages
@import 'pages/landing';
@import 'pages/login';
@import 'pages/postmatchstats';
//fonts 
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,600;9..40,700&family=JetBrains+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  font-family: $code-mono;
  background-color: $dark;
}

.row {
  display: flex;
  flex-direction: row;
}

.root {
  max-width: 100vw;
}