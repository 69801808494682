.general-form {
    background-color: transparentize($color: $medium, $amount: .4);
    backdrop-filter: blur(3px);
    border: 1px solid $light-border;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    border-radius: 8px;

    .general-input {
        padding: 1.25em;
        padding-left: 1em;
        font: 600 16px $code-mono;
        border-radius: 4px;
        margin-top: .5em;
        margin-right: 1.5em;
        margin-left: 1.5em;
        height: 2.5em;
        background-color: $white;
    }

    .general-input:focus {
        box-sizing: border-box;
        border: 2px solid $light-green;
        padding: calc(1.25em - 4px);
        padding-left: calc(1.25em - 6px);
    }

    .submit-button {
        width: 150px;
        height: 40px;
        margin: auto;
        margin-top: 1em;
        text-align: center;
        background-color: $dark;
        font: 600 16px $main-sans;
        color: $white;
        padding-top: .5em;
        padding-bottom: .5em;
        border-radius: 2px;
    }

    .error-container {
        background-color: #ed4337;
        margin-top: 1em;
        font: 400 16px $main-sans;
        color: $white;
        padding: .5em;
        margin-right: 1.5em;
        margin-left: 1.5em;
    }

    .success-container {
        background-color: #4bb543;
        margin-top: 1em;
        font: 400 16px $main-sans;
        color: $white;
        padding: .5em;
        margin-right: 1.5em;
        margin-left: 1.5em;
    }
}