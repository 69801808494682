.keyboard {
    display: flex;
    flex-direction: column;
    width: 1100px;
    background-color: $darker-green;
    border: 4px solid $white;
    border-radius: 8px;
    padding: 1px;

    .key-row {
        display: flex;
        width: 100%;
    }

    .outer-key {
        background-color: $dark-green;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 4px;
        transition: filter 0.2s ease-in-out, transform 0.2s linear;
    }

    .key {
        margin: 1px;
        color: $white;
        position: relative;
        box-sizing: border-box;
        resize: horizontal;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-size: 16px;
    }

    .inner-key {
        background-color: $light-green;
        padding: 8px;
        height: calc(100% - 14px);
        width: calc(100% - 14px);
        border-radius:6px;
        border: 1px solid #B5C5C7;
    }

    .square-key {
        width: calc(100% / 16);
        aspect-ratio: 1/1;
    }   

    .active.outer-key {
        filter: brightness(.86);
        transform: scale(.94);
    }

    .function-lvl-1-key {
        width: calc(100% / 13);
        aspect-ratio: 5/4;
        flex-grow: 1;

    }

    .function-lvl-2-key {
        width: calc(100% / 10);
        flex-grow: 1;

    }

    .function-lvl-3-key {
        width: calc(100% / 9);
        flex-grow: 1;
    }

    .function-lvl-4-key {
        width: calc(100% / 7);
        flex-grow: 1;
    }

    .space-bar-key {
        width: calc(100% / 2);
        .inner-key {
            padding: 0;
        }
    }
}