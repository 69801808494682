.welcome-container {
    display: flex;
    margin-top: 4em;

    @media (max-width: $mobile) {
        margin-top: 2em;
    }

    .welcome-title {
        color: $white;
        font: 600 1.75em $main-sans;
        margin-bottom: .5em;
        text-align: left;
    }

    .divider {
        width: 25%;
        height: 1px;
        background-color: $white;
        margin-bottom: 1em;
    }

    .welcome-cards {
        display: flex;
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        flex-wrap: nowrap;
        justify-content: center;
        justify-items: center;

        @media (max-width: $mobile) {
            width: 90%;
        }

        @media (max-width: $tablet) {
            flex-direction: column;
        }
    }

    .welcome-card {
        background-color: $medium;
        padding: 1em;
        border-radius: 8px;
        transition: box-shadow .5s ease-in-out;
        border: 1px solid $light-border;
    }

    .welcome-card:hover {
        box-shadow: 1px 1px 15px rgba($color: #000000, $alpha: .4);
    }

    .welcome-cards-left {
        flex-basis: 60%;
        flex-shrink: 2;

        @media (max-width: $mobile) {
            flex-basis: 100%;
        }
    }

    .welcome-cards-right {
        display: flex;
        flex-direction: row;
        flex-basis: 70%;

        @media (max-width: $tablet) {
            margin-top: 1em;
        }

        @media (max-width: $mobile) {
            flex-direction: column;
            flex-basis: 100%;
            margin-top: 0;
        }

    }

    .welcome-profile-card {
        .profile-tags {
            margin-top: .75em;
            margin-bottom: 1em;
            white-space: nowrap;
        }

        .tag {
            padding: .25em;
            padding-left: .75em;
            padding-right: .75em;
            margin-right: .5em;
        }

        .profile-card-email {
            color: $white;
            font: normal 1em $main-sans;
        }

        .profile-disclaimer {
            font: normal .75em $code-mono;
            color: $white;
            background-color: rgba(0, 0, 0, .2);
            padding: 1em;
            border-radius: 10px;
        }
    }

    .welcome-history-card {
        flex-basis: 55%;

        @media (max-width: $tablet) {
            flex-basis: calc(50% - 1em);
            margin-left: 0;
        }

        @media (max-width: $mobile) {
            width: 100%;
            margin-bottom: 1em;

        }
    }

    .welcome-leaderboard-card {
        min-width: 250px;
        flex-shrink: 10;
        flex-basis: 50%;

        @media (max-width: $tablet) {
            flex-basis: 50%;
        }

        @media (max-width: $mobile) {
            margin-bottom: 3em;
        }

    }

    a {
        text-decoration: none;
    }

    .create-game {
        background-color: $light;
        margin-top: 16px;
        padding: 16px;
        color: $white;
        text-align: center;
        border-radius: 8px;
        font: bold 24px $main-sans;
        border: 2px solid $white;
        width: 100%;
    }

    .welcome-active-users {
        margin-top: 16px;

        .no-active-users {
            font-style: italic;
            color: $white;
        }
    }

    .accept-game-request {
        margin-top: 16px;

        .accept-game-request-button {
            background-color: $light;
            padding: 8px;
            color: $white;
            border-radius: 4px;
            font-family: $main-sans;
        }
        
        .accept-game-request-button:last-child {
            margin-left: 8px;
        }
    }
}