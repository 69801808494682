.graphic-container {
    transform: rotate(20deg);
    margin-left: 50px;
    max-width: 45vw;
}

@keyframes float {
  from {
    margin-top: 1em;
  }

  50% {
    margin-top: 2em;
  }

  to {
    margin-top: 1em;
  }
}

@keyframes hfloat {
  from {
    margin-left: 0;
  }

  50% {
    margin-left: 10px;
  }

  to {
    margin-left: 0;
  }
}
@keyframes hfloat2 {
  from {
    margin-left: 100px;
  }

  25% {
    margin-left: 90px;
  }

  to {
    margin-left: 100px;
  }
}
